import { initMap } from 'js/outdoors/map_module.js'
import { initFiltering } from 'js/outdoors/filtering.js'

var isMobile = false;

$(document).ready( () => {
  if ( $(window).width() <= 768 ) {
    isMobile = true
    $('.content-wrapper').addClass('mobile')
    
    $('.side-nav.outdoors').addClass('hide')
    $('.side-nav.outdoors.mobile').removeClass('hide')
  } else {
    $('.side-nav.outdoors.mobile').addClass('hide')
  }

  $(window).bind('orientationchange', function (event) {
    setTimeout(()=>{
      var newLayout = $(window).width() <= 768

      if (isMobile != newLayout) {
        location.reload(true);
      }
    },50)
  });

  initMap();
  initFiltering();
})

export { isMobile };