export function createTour(tourData, firstScene){
  let pano = pannellum.viewer("pano", {
    "autoLoad": true,
    "minPitch": -80,
    "hfov": 120,
    "default": {
      "firstScene": firstScene,
      "sceneFadeDuration": 1000
    },
    "scenes": scenesForTour(tourData)
  });

  initControls(pano);
  return pano;
}

function scenesForTour(tourData){
  let scenes = {};

  $.each(tourData.scenes, (k,v)=>{
    scenes[v.name] = {
      "title": v.title,
      "pitch": v.pitch,
      "yaw": v.yaw,
      "panorama": v.photo_url,
      "hotSpots": hotspotsForScene(v.hotspots)
    };
  });
  return scenes;
}

function hotspotsForScene(hotspots){
  let hotspotArray = [];

  $.each(hotspots, (k,v)=>{
    hotspotArray.push({
      "type": v.is_type,
      "pitch": v.pitch,
      "yaw": v.yaw,
      "sceneId": v.next_scene,
      "text": v.description
    });
  });
  return hotspotArray;
}

function initControls(viewer){
  function destroyTour() {
    viewer.destroy();
    $(".tour-tint").remove();
  }

  function removeTourExitHandlers() {
    $('body').off('mousedown', '.tour-tint', tourRemovalFromTint)
    $('body').off('click', '.controls .exit', tourRemovalFromClose)
  }

  function tourRemovalFromClose(e) {
    destroyTour()
    removeTourExitHandlers()
  }

  function tourRemovalFromTint(e) {
    console.log(e)
    if (e.target.classList.value == 'tour-tint') {
      destroyTour()
      removeTourExitHandlers()
    }
  }

  $("body").one("click", ".controls .exit", tourRemovalFromClose);
  $("body").on("mousedown", ".tour-tint", tourRemovalFromTint);
}