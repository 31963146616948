import { createTour } from 'js/outdoors/pannellum.js'
import { isMobile } from 'js/outdoors/outdoors.js';
import { executeSearch } from 'js/outdoors/filtering.js';
import { 
  createPolylineGeoJson, 
  createExplorableGeoJson,
  buildOutdoorExplorables,
  fitAndZoom
} from 'js/outdoors/geojson_helpers.js';
import { buildExploreCard } from 'js/outdoors/explore.js';


function GoogleMap(){
  let popupPool = []
  let trailPopupPool = []
  let exploredTourData = {};
  
  var currentModel = '';

  var map;
  var mapData = {};
  var pointsForBounds = [];
  var foodFilter;

  // Duplicated
  function fitBounds() {
    var bounds = new mapboxgl.LngLatBounds();

    $.each(pointsForBounds, function(k,v){
      bounds.extend(v);
    });

    map.fitBounds(bounds, {padding: 60});    
  }

  function setFoodFilter(filter){
    foodFilter = filter;
  }

  function initialMapData(){
    $.ajax({
      method: "GET",
      url: '/outdoors/init_map.js',
      success: function({
        pois,
        trails
      }){
        populateMap(pois, trails);
      }
    })
  }

  function populateMap(pois, trails){
    buildPolyline(trails);
    createMarkers(pois);
    // fitBounds();
  }
  
  // metersPerPixel = 40007000 * Math.cos(30.368743 * Math.PI / 180) / (512 * Math.pow(2, 10))
  function initMap(){
    let popupToOpen = [];
    mapboxgl.accessToken = 'pk.eyJ1IjoianRyZWlkIiwiYSI6ImNrYm1tMjBhNTFrN2QyeG14M2FhNW16MXAifQ.-skvIQBu6FpABTueujT5-w';

    function setPopupToOpen(popup){
      popupToOpen.push(popup)
    }

    map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/jtreid/ckmm8boy30y7i17ntcpyigd99',
      center: [-86.204044, 30.368743],
      zoom: 10
    });

    let icons = [
      // TODO: when deployed, change to production assets
      // These are for Explorable places
      ['https://doemeraldcoast.s3.amazonaws.com/map-icons/development/scene-37.png?cacheblock=true', 'scene'],
      ['https://doemeraldcoast.s3.amazonaws.com/map-icons/development/parking-37.png?cacheblock=true', 'parking'],
      ['https://doemeraldcoast.s3.amazonaws.com/map-icons/development/restroom-37.png?cacheblock=true', 'restroom'],
      ['https://doemeraldcoast.s3.amazonaws.com/map-icons/development/water-37.png?cacheblock=true', 'water'],
      ['https://doemeraldcoast.s3.amazonaws.com/map-icons/development/bench-37.png?cacheblock=true', 'bench'],

      ['https://dec-production.s3.amazonaws.com/map-icons/beach-umbrella-37.png?cacheblock=true', "beach_access"],
      ['https://dec-production.s3.amazonaws.com/map-icons/park-37.png?cacheblock=true', 'park'],
      ['https://dec-production.s3.amazonaws.com/map-icons/ship-37.png?cacheblock=true', 'bay_access'],
      ['https://dec-production.s3.amazonaws.com/map-icons/trail-37.png?cacheblock=true', 'trail'],
      ["https://dec-production.s3.amazonaws.com/map-icons/beer-37.png?cacheblock=true", 'beer'],
      ["https://dec-production.s3.amazonaws.com/map-icons/breakfast-37.png?cacheblock=true ", 'breakfast'],
      ["https://dec-production.s3.amazonaws.com/map-icons/coffee-37.png?cacheblock=true", 'coffee'],
      ["https://dec-production.s3.amazonaws.com/map-icons/hamburger-37.png?cacheblock=true ", 'hamburger'],
      ["https://dec-production.s3.amazonaws.com/map-icons/hotdog-37.png?cacheblock=true", 'hotdog'],
      ["https://dec-production.s3.amazonaws.com/map-icons/ice-cream-37.png?cacheblock=true ", 'ice-cream'],
      ["https://dec-production.s3.amazonaws.com/map-icons/noodles-37.png?cacheblock=true ", 'noodles'],
      ["https://dec-production.s3.amazonaws.com/map-icons/pasta-37.png?cacheblock=true ", 'pasta'],
      ["https://dec-production.s3.amazonaws.com/map-icons/pizza-slice-37.png?cacheblock=true ", 'pizza-slice'],
      ["https://dec-production.s3.amazonaws.com/map-icons/sandwich-37.png?cacheblock=true", 'sandwich'],
      ["https://dec-production.s3.amazonaws.com/map-icons/seafood-37.png?cacheblock=true ", 'seafood'],
      ["https://dec-production.s3.amazonaws.com/map-icons/spirits-37.png?cacheblock=true ", 'spirits'],
      ["https://dec-production.s3.amazonaws.com/map-icons/steak-37.png?cacheblock=true ", 'steak'],
      ["https://dec-production.s3.amazonaws.com/map-icons/sushi-37.png?cacheblock=true ", 'sushi'],
      ["https://dec-production.s3.amazonaws.com/map-icons/taco-37.png?cacheblock=true", 'taco'],
      ["https://dec-production.s3.amazonaws.com/map-icons/wine-37.png?cacheblock=true", 'wine'],
      ["https://dec-production.s3.amazonaws.com/map-icons/asian-37.png?cacheblock=true", 'asian'],
      ["https://dec-production.s3.amazonaws.com/map-icons/bbq-37.png?cacheblock=true", 'bbq'],
      ["https://dec-production.s3.amazonaws.com/map-icons/food-37.png?cacheblock=true", 'food']
    ]

    map.on('load', function () {
      $.each(icons, (k,v)=>{
        map.loadImage(v[0], function(error, image) {
          if (error) throw error;
          map.addImage(v[1], image);
        });  
      });
  
      map.addControl(new mapboxgl.NavigationControl());
      initialMapData();
    });

    map.addControl(new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true
      },
      trackUserLocation: true,
      showAccuracyCircle: false
    }));

    let currentlyHoveredItemChanged = false;
    let currentlyHoveredIs = 'NOTHING';

    map.on('mouseleave', 'all', function (e) {
      popupToOpen = [];
      currentlyHoveredIs = 'NOTHING';
    });

    map.on('mousemove', 'all', function (e) {
      // var features = map.queryRenderedFeatures(e.point, {
      //   layers: ['all']
      // });
      if (currentlyHoveredIs != e.features[0].properties.name) {
        setPopupToOpen(e.features[0].properties)

        setTimeout(function(){
          if (popupToOpen.length > 0) {
            getPopupData(popupToOpen[popupToOpen.length - 1])
          }
        },250)
      }

      currentlyHoveredIs = e.features[0].properties.name;
    }); 

    map.on('touchend', 'all', function (e) {
      let item = e.features[0].properties
      
      e.originalEvent.preventDefault();

      getPopupData(item)
    });

    map.on('mouseenter', 'trails', function (e) {
      let item = e.features[0].properties

      trailPopupPool.push(item)

      setTimeout(function(){
        if (trailPopupPool.length > 0) {
          getPopupData(trailPopupPool[0], e.lngLat)
        }
      },250)
    });

    map.on('mouseleave', 'trails', function (e) {
      trailPopupPool = [];
    });

    map.on('click', 'explorables', function (e) {
      let properties = e.features[0].properties

      if (properties.icon == 'scene') {
        $('body').append(exploredTourData.viewer)
        createTour(exploredTourData.tour, e.features[0].properties.name)
      }
    });

    attachTourCreator();
    enableExplorables();
  }

  function popupWidth(){
    if (isMobile) {
      return "300px";
    } else {
      return "400px";
    }
  }

  function removeAllPopups(){
    $.each(popupPool, function(k, v){
      v.remove();
    });
  }

  function getPopupData(item, lngLat = []){
    $.ajax({
      method: "GET",
      url: `/map_cards/show/${item.model}/${item.id}.js`,
      success: (res)=>{
        lngLat = res.poi.model == 'Trail' ? lngLat : [res.poi.lng, res.poi.lat]


        removeAllPopups();
        popupPool.push(new mapboxgl.Popup({
          offset: 25
        }).setLngLat(lngLat)
          .setHTML(baseCard(res.poi))
          .setMaxWidth(popupWidth())
          .addTo(map));          
      },
      error: (err)=>{
        console.log("Whaaa....Whaaa... whaaaAAaaAAAAaaaa", err)
      }
    });    
  }

  function createTrailGeoJson(trails){
    function returnLngLatPairs(array){
      let pairs = []

      $.each(array, function(k,v){
        pairs.push([v.lng, v.lat])
      })
      return pairs;
    }

    let geoJson = {
      "type": 'FeatureCollection',
      "features": []
    }

    $.each(trails, function(k,trail){
      pointsForBounds.concat(trail.points)

      geoJson.features.push({
        type: "Feature",
        properties: {
          is_type: trail.is_type,
          model: 'Trail',
          name: trail.name,
          id: trail.id
        },
        geometry: {
          type: 'LineString',
          coordinates: returnLngLatPairs(trail.points)
        }
      })
    });

    return geoJson;
  }

  function buildPolyline(lines) {
    let trailGeoJson = createTrailGeoJson(lines);

    // Duplicated for Trails
    map.addSource('trails', {
      'type': 'geojson',
      'data': trailGeoJson
    });

    map.addLayer({
      'id': 'trails',
      'type': 'line',
      'source': 'trails',
      'layout': {
        'line-join': 'round',
        'line-cap': 'round'
      },
      'paint': {
        'line-color': '#888',
        'line-width': [
          'interpolate', ['linear'], ['zoom'],
          5,1,
          10,3,
          14,8,
          16,10
        ]
      }
    });        
  }

  function getIcon(item) {
    if (foodFilter) {
      return item.is_type.filter(function (n) {
        return foodFilter.indexOf(n) !== -1
      })
    } else {
      return item['is_type']
    }
  }

  function createGeoJson(points){
    let geoJson = {
      "type": 'FeatureCollection',
      "features": []
    }

    function selectIcon(poi){
      if (poi.model == 'Restaurant') {
        if (foodFilter) {
          if(currentModel == 'restaurant' && foodFilter.length > 1){
            return 'food';
          } else {
            return getIcon(poi)[0];
          }
        } else {
          return poi.is_type[0]
        }
      } else {
        return poi.is_type
      }
    }

    $.each(points, function(k,v){
      pointsForBounds.push([v.lng, v.lat])

      geoJson.features.push({
        type: "Feature",
        properties: {
          icon: selectIcon(v),
          model: v.model,
          name: v.name,
          id: v.id
        },
        geometry: {
          coordinates: [v.lng, v.lat],
          type: 'Point'
        }
      })
    })

    return geoJson;
  }

  function createMarkers(points) {
    let geoJson = createGeoJson(points)

    map.addSource('all', {
       type: 'geojson',
       data: geoJson
    });

    map.addLayer({
      id: 'all',
      source: 'all',
      type: 'symbol',
      layout: {
        "icon-allow-overlap": true,
        'icon-image': ['get', 'icon']
      }
    });  
  }

  function enableExplorables() {
    $('.content-wrapper').on('click', '.actions .explore', (e) => {
      let model = $(e.currentTarget).data('model')
      let id = $(e.currentTarget).data('id')

      let baseUrl = {
        Trail: 'trails'
      }

      $.ajax({
        method: 'GET',
        url: `/${baseUrl[model]}/${id}/explore.js`,
        success: function exploreSuccess({
          record, 
          explorables = record.facilities, 
          tour,
          scenes = tour.scenes,
          viewer
        }){
          exploredTourData.viewer = viewer
          exploredTourData.tour = tour
          // Remove all Layers
          deleteMapPoints()
          // createTour()
          // Build new Explore Layers and place them on the map
          //  -  Trail Layer goes under the Features Layer
          let polylineGeoJson = createPolylineGeoJson([record]);
          let {
            pointGeoJson,
            boundsArray
          } = createExplorableGeoJson({explorables, scenes});

          buildOutdoorExplorables(map, {
            polyline: polylineGeoJson,
            explorables: pointGeoJson
          })

          buildExploreCard()

          // Remove current Popup
          removeAllPopups();

          // Setup Tour
          //  - Explorer can enter the tour at any scene they want
          //  - Explorer enters on a click


          // Zoom to encompase the Explore Layers
          fitAndZoom(map, polylineGeoJson, boundsArray)
        }
      });
    })

    $('.content-wrapper').on('click', '.explore-card', ()=>{
      deleteMapPoints()
      $('.explore-card').removeClass('display')

      if ($('.content-wrapper').data('model') == null) {
        initialMapData()
      } else {
        executeSearch()  
      }
    })
  }

  function attachTourCreator() {
    $('.content-wrapper').on('click', '.actions .tour', (e) => {
      let model = $(e.currentTarget).data('model')
      let id = $(e.currentTarget).data('id')

      $.ajax({
        method: 'GET',
        url: `/tours/${id}.js`,
        data: {
          model: model
        }
      });
    });
  }

  function directionsAddress(data) {
    return `${data['address_data']['street']}, ${data['address_data']['city']}, ${data['address_data']['state']}, ${data['address_data']['zip']}`
  }

  function shouldInsertWebLink(data, string = "") {
    if (data["website"] && data["website"].length != 0) {
      string = `<a class='website waves-effect waves-light btn-flat' target='_blank' href='http://${data['website']}'>Website</a>`
    }

    return string
  }

  function shouldInsertExploreLink(data, string = ""){
    if (data.is_explorable) {
      string = `<a class='explore waves-effect waves-light btn-flat' data-id='${data['id']}' data-model='${data['model']}'>Explore</a>`
    }

    return string;
  }

  function shouldInsertTourLink(data, string = "") {
    if (data["has_primary_tour"]) {
      string = `<a class='tour waves-effect waves-light btn-flat' data-id='${data['id']}' data-model='${data['model']}'>Virtual Tour</a>`
    }

    return string
  }

  function shouldActivate(activator){
    return (activator == '' ? '' : 'activator')
  }

  // TODO: Look at removing cardClass
  function baseCard(data, cardClass = '') {
    let activatorClass = shouldActivate(data['display_content'])

    return `
      <div class='card ${cardClass}'>
        <div class='card-image waves-effect waves-block waves-light'>
          <img class='${activatorClass}' src='${data['card_photo_url']}'>
        </div>

        <div class='card-content'>
          <span class='card-title ${activatorClass} grey-text text-darken-4 display-flex flex-row start-start'>
            <div class='title-max ${activatorClass} flex-90'> ${data['name']} </div>
            <i class='material-icons right'>more_vert</i>
          </span>
          <div class='poi-information flex-row start-start wrap'>
            <div class='actions flex-100 display-flex flex-row space-between-start'>
              ${ shouldInsertExploreLink(data) }
              ${ shouldInsertTourLink(data) }
              ${ shouldInsertWebLink(data) }
            </div>
          </div>
        </div>
        <div class='card-reveal'>
          <span class='card-title grey-text text-darken-4'>
            ${data['name']}
            <i class='material-icons right'>close</i>
          </span>
          <div class='card-reveal-wrapper'>
            ${ data['display_content'] }
          </div>
        </div>
      </div>`
  }

  function writePoisToMap({
    filterThrough,
    foodFilter,
    mapData: {
      pois,
      restaurants = [],
      trails = []
    }
  }){
    currentModel = filterThrough

    setFoodFilter(foodFilter);
    pois = pois.concat(restaurants)

    if (trails.length > 0) {
      buildPolyline(trails);
    }

    removeAllPopups();
    createMarkers(pois);
  }

  function deleteMapPoints(){
    if(map.getLayer('all')){
      map.removeLayer('all')
      map.removeSource('all')
    }

    if(map.getLayer('explorables')){
      map.removeLayer('explorables')
      map.removeSource('explorables')
    }

    if(map.getLayer('explorable-polylines')){
      map.removeLayer('explorable-polylines')
      map.removeSource('explorable-polylines')
    }

    if(map.getLayer('trails')){
      map.removeLayer('trails')
      map.removeSource('trails')
    }
  }

  return {
    initMap,
    writePoisToMap: writePoisToMap,
    deleteMapPoints: deleteMapPoints
  }
}

var fn = GoogleMap()
var initMap = fn.initMap
var writePoisToMap = fn.writePoisToMap
var deleteMapPoints = fn.deleteMapPoints

export { initMap, 
  writePoisToMap, 
  deleteMapPoints
}