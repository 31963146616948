// NOTE: Could move this to Admin namespace
import { FormHelpers } from 'js/admin/dashboard/form_helpers.js';

let FormHelpersModule = FormHelpers();

function rebuildEditMap(map, explorables, scenes){
  let { pointGeoJson } = createExplorableGeoJson({explorables, scenes});

  map.removeLayer('explorables');
  map.removeSource('explorables');
  setGeoJsonToMap(map, pointGeoJson, 'explorables');

  FormHelpersModule.clearExplorableSelection();
  FormHelpersModule.clearLonglatForm();
}

function setGeoJsonToMap(map, geoJson, id){
  map.addSource(id, {
    type: 'geojson',
    data: geoJson
  });

  map.addLayer({
    id: id,
    source: id,
    type: 'symbol',
    layout: {
      "icon-allow-overlap": true,
      'icon-image': ['get', 'icon']
    }
  });
}

function buildOutdoorExplorables(map, geoJsonObj){
  map.addSource('explorable-polylines', {
    'type': 'geojson',
    'data': geoJsonObj.polyline
  });

  map.addLayer({
    'id': 'explorable-polylines',
    'type': 'line',
    'source': 'explorable-polylines',
    'layout': {
      'line-join': 'round',
      'line-cap': 'round'
    },
    'paint': {
      'line-color': '#888',
      'line-width': 2
    }
  });

  // Add Facilities to map
  setGeoJsonToMap(map, geoJsonObj.explorables, 'explorables')
}

function explorableUpdateData(namespace, lng, lat){
  let obj = {
    scenes: { scene: {} },
    facilities: { facility: {} }
  };

  let val = obj[namespace];
  val[Object.keys(val)[0]].longlat = `POINT(${lng} ${lat})`;
  val.model = $('.edit.view').data('record-model');
  val.model_id = $('.edit.view').data('record-id');
  val.return_explorables = 'true';

  return val;
}

function setExplorableData(){
  return {
    selection: $('.edit-scenes .longlat-form .selection.selected').data('selection'),
    namespace: $('.edit-point.selected').data('namespace'),
    addExplorable: $('.add .explorable.selected').data('add'),
    explorableId: $('.edit-point.selected').data('explorable-id')
  }
}

function createExplorableGeoJson({
  explorables,
  scenes
}){
  let boundsArray = []
  let geoJson = {
    "type": 'FeatureCollection',
    "features": []
  }

  $.each(explorables, function(k,v){
    boundsArray.push([v.lng, v.lat])

    geoJson.features.push({
      type: "Feature",
      properties: {
        model: 'Facility',
        id: v.id,
        icon: v.is_type,
        name: v.is_type
      },
      geometry: {
        coordinates: [v.lng, v.lat],
        type: 'Point'
      }
    })
  })

  $.each(scenes, function(k,v){
    boundsArray.push([v.lng, v.lat])

    geoJson.features.push({
      type: "Feature",
      properties: {
        icon: 'scene',
        name: v.name
      },
      geometry: {
        coordinates: [v.lng, v.lat],
        type: 'Point'
      }
    })
  })

  return { pointGeoJson: geoJson, boundsArray: boundsArray };
}

function createPolylineGeoJson(trails){
  function returnLngLatPairs(array){
    let pairs = []
    
    $.each(array, function(k,v){
      pairs.push([v.lng, v.lat])
    })
    return pairs;
  }

  let geoJson = {
    "type": 'FeatureCollection',
    "features": []
  }

  $.each(trails, function(k,trail){
    geoJson.features.push({
      type: "Feature",
      properties: {
        is_type: trail.is_type,
        model: 'Trail',
        name: trail.name,
        id: trail.id
      },
      geometry: {
        type: 'LineString',
        coordinates: returnLngLatPairs(trail.points)
      }
    })
  });

  return geoJson;
}

function fitAndZoom(map, polylineGeoJson = [], boundsArray = []){
  var coordinates = polylineGeoJson.features[0].geometry.coordinates.concat(boundsArray);
  var bounds = coordinates.reduce(function (bounds, coord) {
    return bounds.extend(coord);
  }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));

  map.fitBounds(bounds, {
    padding: 40
  });
}

export {
  createPolylineGeoJson,
  createExplorableGeoJson,
  setExplorableData,
  explorableUpdateData,
  rebuildEditMap,
  buildOutdoorExplorables,
  fitAndZoom
}