import 'materialize-css/dist/js/materialize.js'
// import 'materialize-css/js/cash.js'
// import 'materialize-css/js/component.js'
// import 'materialize-css/js/global.js'
// import 'materialize-css/js/anime.min.js'

// import 'materialize-css/js/waves.js'

// import 'materialize-css/js/buttons.js'

import 'js/outdoors/outdoors.js'