import { 
  writePoisToMap, 
  clearSavedPointsArray, 
  deleteMapPoints,
}  from 'js/outdoors/map_module.js'

import {notification} from 'js/outdoors/notification.js'
import { isMobile } from 'js/outdoors/outdoors.js';
import beachAccessImage from 'images/beach-umbrella.png'
import restaurantImage from 'images/food.png'
import bayAccessImage from 'images/ship.png'
import parkImage from 'images/park.png'
import trailImage from 'images/trail.png'

function FilteringDashboard(){

  var iconData = {
    'beach_access': ['beach-umbrella.png', 'height: 38px; margin-top: 5px;'],
    'bay_access': ['ship.png', 'height: 39px; margin-top: 11px;'],
    'trail': ['trail.png', 'height: 44px; margin-top: 5px;'],
    'restaurant': ['food.png', 'height: 42px; margin-top: 7px;'],
    'park': ['park.png', 'height: 49px; margin-top: 5px;']
  }

  var images = {
    'restaurant': restaurantImage,
    'beach_access': beachAccessImage,
    'park': parkImage,
    'trail': trailImage,
    'bay_access': bayAccessImage
  }

  // var locator = {};
  var calculateFrom;
  var geoWatchData = {};

  notification.initialize()

  return {
    init,
    executeSearch
  }

  function init(){
    if (isMobile) {
      initMobileFiltering();
    } else {
      initDesktopFiltering();
    }
  }

  // How does the distance from me work?
  // 1) Default - Searches entire map, no distance selected
  // 2) Searches 1 mile from current device location
  // 3) Searches 2 miles from current device location
  // 4) Searches 5 miles from current device location
  //
  // Actions from default of "All":
  // - Click on 1 Mile button:
  //    1) Remove any previous OverlayViews and draw a 1 Mile view
  //       from current device location and query
  // - Click on Travel Mode icon:
  //    1) Remove any previous Overlay Views
  //    2) Check to make sure that a distance is selected
  //    3) If a distance is selected, use that distance
  //    4) If a distance is not selected, select the 1 Mile distance
  //    5) Create an OverlayView with a radius equal to the selected
  //       distance.
  //    6) Setup GeoWatching to perform query
  //    7) After successful query, update the OverlayView's position
  //       on the map rather than destroying it and rebuilding to 
  //       prevent jitter.

  function initMobileFiltering() {
    var el = $('.content-wrapper.mobile');

    el.on('click', '.outdoor-category', (e) => {
      var filterThrough = $(e.currentTarget).data('filter-through');
      el.data('model', filterThrough);

      categorySearch();

      el.find('.mobile-filtering').addClass('transition-hide')
      findAndReplaceFABImage(filterThrough)
    })

    el.on('click', '.sub-head', (e) => {
      $('.side-nav.outdoors').addClass('s3 hide').removeClass('s12 open mobile-filtering-force-open')
    });

    setupMobileFilteringOverlay();
    initBaseSearch(el)

    // =-=-=-=-=-=-=-=-=-=-=-=-=-=-
    // FAB Options Init
    // =-=-=-=-=-=-=-=-=-=-=-=-=-=-
    el.on('click', '.open-filter-selection', () => {
      $('.side-nav.outdoors').removeClass('s3 hide').addClass('s12 open mobile-filtering-force-open')
    });

    el.on('click', '.revert-to-category-selection', () => {
      $(".filter.fixed-action-btn").removeClass('user-selected');
      clearFilters();

      initialMapState();

      // wait for FAB to fade out, could hook into the transition event
      // but if it fires early its not a huge deal
      setTimeout(() => {
        $(".mobile-filtering").show();
        $(".mobile-filtering").removeClass('transition-hide')
      }, 250)
    });

    // Mobile FAB init
    var elems = document.querySelectorAll('.fixed-action-btn');
    var instances = M.FloatingActionButton.init(elems, {
      direction: 'left',
      hoverEnabled: false
    });
  }

  function initialMapState(){
    $.ajax({
      method: "GET",
      url: '/outdoors/init_map.js',
      success: function(res){
        $('.explore-card').removeClass('display')
        $('.side-nav.outdoors .category-list').show();

        $('.filtering-form-container .append-poi-filtering').html(null);
        $('.filtering-form-container .append-food-filtering').html(null);
        $('.content-wrapper').data('model', null)
        deleteMapPoints();
        writePoisToMap({mapData: res});
      }
    })
  }

  function initDesktopFiltering() {
    var el = $('.content-wrapper:not(.mobile)');

    el.on('click', '.outdoor-category', (e) => {
      // let filterThrough = $(e.currentTarget).data('filter-through');
      el.data('model', $(e.currentTarget).data('filter-through'));

      categorySearch();
    })

    el.on('click', '.sub-head', (e) => {
      initialMapState();
    });

    initBaseSearch(el)
  }

  function categorySearch(){
    $.ajax({
      method: 'GET',
      url: '/outdoors/select-category.js',
      data: searchData(),
      success: function(res){
        // Remove any explore cards that may be present
        $('.explore-card').removeClass('display')
        hideCategoryList();
        deleteMapPoints();
        writePoisToMap(res);
        buildViews(res);
      },
      error: function(err){ console.log(err) }
    });    
  }

  function executeSearch(coords = false) {
    calculateFrom = coords

    $.ajax({
      method: 'GET',
      url: '/outdoors/search.js',
      data: searchData(),
      success: function(res){

        // Remove any explore cards that may be present
        $('.explore-card').removeClass('display')

        // If the current request comes under a calculateDistance Query
        //  - Cache current map points so we can remove 
        //    them after render
        // Clear the current map point container
        // Write new POIs to the point container
        // Remove cached map points

        if (res.calculateDistance) {
          deleteMapPoints();
          writePoisToMap(res);
        } else {
          deleteMapPoints();
          writePoisToMap(res);
        }

        if (!geoWatchData.watching) {
          notification.info(constructMessage(res))
        }
      },
      error: function(err){
        console.log(err)
      }
    })
  }

  // TODO: Move these functions to Notification.js
  function constructMessage({
    filterThrough,
    mapData: {
      pois,
      restaurants = [],
      trails = []
    }
  }){
    let totalPoints = pois.length + trails.length
    let category = {
      'beach_access': ['Beach', 'es'],
      'park': ['Park', 's'],
      'restaurant': ['Restaurant', 's'],
      'bay_access': ['Boat ramp', 's'],
      'trail': ['Trail', 's']
    }

    return {
      title: `${pluralize(totalPoints, category[filterThrough][0], category[filterThrough][1])}`,
      body: `${totalPoints == 1 ? 'matches': 'match'} your choices`
    }
  }

  function pluralize(count, noun, suffix = 's'){
    return `${count} ${noun}${count !== 1 ? suffix : ''}`;
  }

  function buildViews(res){
    $('.side-nav.outdoors .append-poi-filtering').html(res.view)
    $('.side-nav.outdoors .append-food-filtering').html(res.foodFilterView)
  }

  function findAndReplaceFABImage(selectedCategory) {
    let imageContainer = $('.filter.fixed-action-btn .material-icons');

    imageContainer.find('img').remove();
    imageContainer.append(buildCategoryImageFrom(selectedCategory));
  }

  function buildCategoryImageFrom(selectedCategory) {
    let img = $(`<img src=${images[selectedCategory]}><img/>`);
    img.attr('style', iconData[selectedCategory][1]);
    return img;
  }

  function clearFilters() {
    $('.distance-from-me-choices .choice.selected').removeClass('selected')
    $.each($('.filter:checked'), (k, v) => {
      $(v).prop('checked', false)
    })

    $.each($('.food-filter'), (k, v) => {
      $(v).addClass('inactive')
    })
  }

  function setupMobileFilteringOverlay() {
    let mobileFiltering = document.querySelector(".mobile-filtering");

    mobileFiltering.ontransitionend = function (event) {
      if ($(mobileFiltering).hasClass('transition-hide')) {
        $(event.currentTarget).hide();
        $('.filter.fixed-action-btn').addClass('user-selected');
      } else {
        $('.filter.fixed-action-btn').removeClass('user-selected');
      }
    }
  }

  function initBaseSearch(el) {
    el.on('change', '.filter', (e) => {
      executeSearch();
    });

    el.on('click', '.food-filter', (e) => {
      $(e.currentTarget).toggleClass('inactive');

      executeSearch();
    });
  }

  function foodFilters() {
    var filterArray = [];

    $.each($('.food-filter:not(.inactive)'), (k, v) => {
      filterArray.push($(v).data('food-filter'))
    })

    return filterArray;
  }

  function userChoices() {
    var choices = [];

    $.each($('.filter:checked'), (k, v) => {
      choices.push($(v).attr('id'))
    })

    return choices;
  }

  function searchData() {
    return {
      search: {
        filter_through: $('.content-wrapper').data('model'),
        choices: userChoices(),
        food_filter: foodFilters(),
        // This was used to build distance calculations.
        // Took this out for now
        distance: distanceCalculation(),
        isMobile: isMobile
      }
    };
  }

  function distanceCalculation(){
    return {
      calculate: false
    };
  }

  function hideCategoryList(){
    $('.side-nav.outdoors .category-list').hide();    
  }
}

var initFiltering = FilteringDashboard().init
var executeSearch = FilteringDashboard().executeSearch

export { initFiltering, executeSearch }