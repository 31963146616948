function FormHelpers(){
  function clearExplorableSelection(){
    $('.add .explorable').removeClass('selected');
    $('.edit-point').removeClass('selected');
  }

  function setFormLonglat(point){
    $('.longlat-form input.lng').val(point.data('lng'))
    $('.longlat-form input.lat').val(point.data('lat'))
  }

  function clearLonglatForm(){
    $('.longlat-form input.lng').val(null)
    $('.longlat-form input.lat').val(null)
  }

  return {
    clearExplorableSelection,
    setFormLonglat,
    clearLonglatForm
  }
}

export {
  FormHelpers
}