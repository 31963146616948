// Should publish notifications based on input parameters
// Should accept notification variables and publish notifications
// Should handle all interactions with, and defaults for, the notification library
// 
// 
import * as Toastr from 'toastr/toastr.js';
import { isMobile } from 'js/outdoors/outdoors.js';

function Notification(){
	var defaultOptions = {  };
	var toastr = Toastr;

	function initialize(opts = defaultOptions){
		toastr.options = opts;
	}

  // function info(title, body, callback = removeImmediately){
  function info({
    body,
    title,
    timeout = 1500
  }){
    if (isMobile) {
      removeImmediately();
      toastr.info(body, title, {timeOut: timeout});
    }
  }

  function removeImmediately(){
    toastr.remove();
  }

	return {
		initialize,
    info
	}
}

var notification = Notification()

export { notification }